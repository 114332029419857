import axios from "axios";
import router from "@/router";

const state = {
    jwt: localStorage.getItem('t'),
    endpoints: {
        obtainJWT: 'https://avote-backend.maximedenis.com/auth/obtain_token',
        refreshJWT: 'https://avote-backend.maximedenis.com/auth/refresh_token'
    },
    me: {},
    memberships: [],
    isAuthenticated: false,
    isAdmin: false,
};

const getters = {
};

const actions = {
    async me(context, path) {
        await axios.get("/auth/users/me/")
            .then(async response => {
                await context.commit('setMe', response.data)
                await context.commit('setIsAuthenticated', true)
                await context.commit('setIsAdmin', response.data.is_staff)
                if (path !== undefined) {
                    await router.push(path)
                }
            })
            .catch(error => {
                console.log(error)
            })
        },
    async getMyMemberships(context) {
        await axios.get(`/internal/users/${context.state.me.id}/memberships`)
            .then(response => {
                context.state.memberships = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }
};

const mutations = {
    setMe(state, user) {
        state.me = user;
        },
    setIsAuthenticated(state, value) {
        state.isAuthenticated = value;
        },
    setIsAdmin(state, value) {
        state.isAdmin = value;
        },
    setMemberships(state, memberships) {
        state.memberships = memberships;
        },
};

export default {
  state,
  getters,
  actions,
  mutations
};
