<template>
  <div v-if="updateExists" class="flex items-center justify-around absolute z-50 h-screen w-full backdrop-blur-sm">
    <div class="flex flex-col justify-between bg-white rounded-xl p-4 custom-shadow h-1/4 w-3/4">
      <h3 class="text-sky-blue text-xl">NEW VERSION!</h3>
      <p class="text-gray-500">A newer version is available. Click on the button to load it!</p>
      <button
          class="inline-flex justify-center rounded-md border border-transparent bg-sky-blue px-4 py-2 text-sm font-medium text-white hover:bg-night-blue focus:outline-none focus-visible:ring-2 focus-visible:ring-night-blue focus-visible:ring-offset-2"
          @click="refreshApp">
        UPDATE
      </button>
    </div>
  </div>

  <div
      v-if="$store.state.isLoading"
      class="flex items-center justify-around absolute z-50 h-screen w-full backdrop-blur-sm text-center">
    <div class="lds-dual-ring"></div>
  </div>
  <div class="flex flex-col h-screen">
    <router-view :key="$route.fullPath" class="flex-grow lato font-extralight overflow-y-scroll"/>
    <AppMenu v-if="$store.state.isAuthenticated"></AppMenu>
  </div>
</template>

<script>
import axios from "axios";
import update from './mixins/update'
import AppMenu from "@/components/Menu/AppMenu";

export default {
  components: {AppMenu},
  mixins: [update],
  async beforeMount() {
    document.title = 'Avoté!'
    await this.$store.commit('initializeStore')

    const token = this.$store.state.jwt

    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
      if (this.$store.state.loadingUser) {
        await this.$store.dispatch('login')
        await this.$store.dispatch('me')
        await this.$store.dispatch('getMyMemberships')
        // Todo: Get team data

      }
    } else {
      axios.defaults.headers.common['Authorization'] = ''
    }
  },
}
</script>

<style lang="scss">
.wrapper {
  width: 0.75%;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #2678ff transparent #2678ff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-loading-bar {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  &.is-loading {
    height: 80px;
  }
}

</style>
